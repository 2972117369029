<template>
	<v-dialog
		v-model="dialog"
		fullscreen
		hide-overlay
		persistent
		width="300"
	>	
		<v-container fluid fill-height style="background-color: rgba(238, 238, 238, 1);">
			<v-row justify="center" align="center">
				<v-col cols="12" align="center">
					<v-img
						class="mb-6"
						contain
						height="100"
						:src="require('@/assets/logo_gavcom.png')"
					></v-img>

					<h2 class="font-weight-bold grey--text">
						<span>Cerrando sesión</span>
					</h2>

					<h3 class="font-weight-bold grey--text pb-8">
						¡¡Muchas gracias, vuelva pronto!!
					</h3>
						
					<v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
				</v-col>
			</v-row>
		</v-container>
	</v-dialog>
</template>

<script>
// Mixins
import MainExternalData from '@/mixins/main-external-data'
import { mapState } from 'vuex'

export default {
	mixins: [MainExternalData],
	data() {
		return {
			dialog: true,
		}
	},

	computed: {
    ...mapState(["sessionTimedOut", "token"]),
	},
	
	async created() {
		if (!this.sessionTimedOut) {
			await this.logout();	
		}

		this.$store.dispatch('cleanToken')
		setTimeout(() => 
		{  
			this.$router.push({ name: 'Login' });
		}, 2000);
	},

	methods: {
		async logout() {
			let me=this;

			let request = {
				'token': me.token
			}
			request = Object.assign({}, me.baseRequest, request);
			await me.getObjectResponseWithoutHeaders('api/Account/Logout', request).then(data => {
				if (data) {
				}
			}).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
			});
		},
	},
}
</script>